// params file is generated via the getParams.sh script
import { poolId, poolClientId } from '../params';

const dev = {
  cognito: {
    // Amazon Cognito Region
    region: 'eu-west-1',
    // Amazon Cognito User Pool ID
    userPoolId: poolId,
    // Amazon Cognito Web Client ID
    userPoolWebClientId: poolClientId,
    mandatorySignIn: true,
    authEnabled: true, // Setting this flag to false bypasses Cognito for convenience in dev
  },
  APIDomain: 'https://api.dev.algasat.com',
};

const prod = {
  cognito: {
    // Amazon Cognito Region
    region: 'eu-west-1',
    // Amazon Cognito User Pool ID
    userPoolId: poolId,
    // Amazon Cognito Web Client ID
    userPoolWebClientId: poolClientId,
    mandatorySignIn: true,
    authEnabled: true, // Setting this flag to false bypasses Cognito for convenience in dev
  },
  APIDomain: 'https://api.algasat.com',
};

let config;

switch (process.env.REACT_APP_STAGE) {
  case 'dev':
    config = dev;
    break;
  case 'prod':
    config = prod;
    break;
  default:
    config = dev;
}

export default {
  ...config,
};
